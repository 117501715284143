
import React, { createRef, useState } from 'react';
import NFTConnectionForm from '../Home/components/NFTConnect/NFTConnect';

const NFTConnect = () => {
  return (
    <>
      {/* <MainHeader /> */}
      <NFTConnectionForm />
      {/* <Footer /> */}
    </>
  );
};

export default NFTConnect;
