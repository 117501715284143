import { environment } from "../environment";
export var smartContractAddress;

if (environment == "test") {
  smartContractAddress = "0x9822919D736649d59cC6c871782297223a15757D"  //Goerli Testnet NFT smart contract
  // smartContractAddress = "0x1f79570a0c3a9eeef4f7d84b688973536e6447f2"; //Latest RInkeby Testnet NFT smart contract address
  // smartContractAddress = "0x6be5A1996f73aa0e7e15A0Cf302fb0901e638EF0"; //Rinkeby Testnet NFT smart contract address
}
else if (environment == "prod") {
  smartContractAddress = "0x5E5Dc4385b87b342A8B982cCa1bef8cB5121E737" //Mainnet NFT smart contract address
}

export const network = () => {
  if(environment == "test") {
    return "goerli";
      // return "rinkeby";
    }
      else if (environment == "prod") {
      return "mainnet";
    }
  }
// export default smartContractAddress;