import React from "react";
import {sx} from './CollabCss';
import { Box, Button, Typography } from '@mui/material';
import Card from "./components/Card/Card";
import {data, heading} from './Data/Data';

const Collab = () => (
  <Box sx={sx.root}>
    <Box sx={sx.heading}>
      <Typography 
        color='background.light' 
        sx={sx.heading} 
        fontFamily='Roboto'
        textshadow='0px 0px 57.321px rgba(255, 255, 255, 0.55)'
        >
          {heading}
      </Typography>
    </Box>
    <Box sx={sx.cardContainer}>
      {
        data.map((card) => (
          <Card 
            key={card.text1}
            background={card.background}
            text1={card.text1}
            text2={card.text2}
          />
        ))
      }
    </Box>
  </Box>
)

export default Collab;