import React, { forwardRef } from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Rectangle from '../../../../assets/images/logo/Rectangles.png';
import Art from '../../../../assets/images/logo/art.png';
import Stroke from '../../../../assets/images/logo/stroke.png';
import { sx, JoinButton } from './FantasyCss';
import { heading, subHeading1, subHeading2, subHeading3, buttonText } from './Data/Data';


const Fantasy = ({ mintButtonClick }) => (
	<Box sx={sx.root}>
		<Box sx={sx.leftContainer}>
			<Box sx={sx.rectangle} />
			<Typography
				sx={sx.heading}
			>
				{heading}
			</Typography>
			<Typography
				sx={sx.subHeading}
			>
				{subHeading1}
			</Typography>
			<Typography
				sx={sx.subHeading}
			>
				{subHeading2}
			</Typography>
			<Typography
				sx={sx.subHeading}
			>
				{subHeading3}
			</Typography>
			<Box sx={sx.btnContainer}>
				<JoinButton
					sx={sx.buttonCss}
					onClick={mintButtonClick}
				>
					{buttonText}
				</JoinButton>
			</Box>
		</Box>
		<Box sx={sx.rightContainer}>
			<Box sx={sx.overlayImage1}>
				<img src={Art} style={sx.overlay1} alt='Art' />
			</Box>
			<Box sx={sx.overlayImage2}>
				<img src={Stroke} style={sx.overlay2} alt='Mobile' />
			</Box>
		</Box>
	</Box>
);


export default Fantasy;
