const BP1 = '@media (max-width: 899px)';

export const sx = {
	card: {
		width: '367px',
		aspectRatio: '300 / 388',
		borderRadius: '32px',
		padding: '24px 24px 0px 24px',
		display: 'flex',
		flexDirection: 'column',
		margin: '25px',
		background: '#190C29',
		'&:hover': {
			background: '#35234B',
		},
		[BP1]: {
			width: '100%',
			margin: '4px 0 ',
		},
	},
	text: {
		fontSize: '14px',
	},
	imageContainer: {
		width: '150px',
		height: '150px',
		borderRadius: '22px',
		'& > img': {
			width: '150px',
			height: '150px',
			borderRadius: '22px',
		},
		[BP1]: {
			width: '100px',
			height: '100px',
			borderRadius: '16px',
			'& > img': {
				width: '100px',
				height: '100px',
				borderRadius: '16px',
			},
		},
	},
	socialConnect: {
		display: 'flex',
		alignSelf: 'flex-start',
		mt: 'auto',
		mb: '5%',
		[BP1]: {
			gap: '40%',
			alignSelf: 'end',
			px: '10px',
		},
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
		mt: '24px',
		[BP1]: {
			mt: 0,
			ml: '24px',
		},
	},
	fullNameContainer: {
		display: 'flex',
		flexDirection: 'row',
		[BP1]: {
			flexDirection: 'column',
		},
	},
	nameContainer: {
		fontFamily: 'Open Sans',
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: '20px',
		lineHeight: '27px',
		letterSpacing: '0.1em',
		textTransform: 'uppercase',
		color: '#FFFFFF',
		'& + &': {
			marginLeft: '8px',
		},
		[BP1]: {
			fontSize: '16px',
			'& + &': {
				marginLeft: 0,
			},
		},
	},
	introContainer: {
		fontFamily: 'Open Sans',
		fontStyle: 'normal',
		fontWeight: '800',
		fontSize: '16px',
		lineHeight: '24px',
		color: 'rgba(255, 255, 255, 0.6)',
		mt: '32px',
	},
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		[BP1]: {
			flexDirection: 'row',
		},
	},
};
