import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const JoinButton = styled(Button)({
	boxShadow: 'none',
	textTransform: 'none',

	'&:active': {
		boxShadow: 'none',
	},
});

const BP1 = '@media (max-width: 899px)';

export const sx = {
	root: {
		px: '20px',
		pt: '9%',
		pb: '9%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		[BP1]: {
			px: '2%',
		},
	},
	headingContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		px: '23%',
		[BP1]: {
			px: '0%',
		},
	},
	heading: {
		fontSize: '42px',
		fontStyle: 'normal',
		fontFamily: 'SchnyderM-Light-App',
		fontWeight: 'normal',
		lineHeight: '50px',
		letterSpacing: '4px',
		textAlign: 'center',
		color: '#FFFFFF',
		textShadowColor: 'rgba(255, 255, 255, 0.55)',
		textShadowOffset: { width: 0, height: 0 },
		textShadowRadius: 57.321,
		[BP1]: {
			fontSize: '32px',
			lineHeight: '38px',
		},
	},
	subHeading: {
		fontSize: '16px',
		lineHeight: '24px',
		fontWeight: '800',
		textAlign: 'center',
		fontFamily: 'Open Sans',
		color: 'rgba(255, 255, 255, 0.6)',
		textShadowColor: 'rgba(255, 255, 255, 0.25)',
		textShadowOffset: { width: 0, height: 0 },
		textShadowRadius: 8,
		padding: '3% 8%',
		[BP1]: {
			fontSize: '15px',
			mt: '15px',
		},
	},
	cardContainer: {
		mt: '80px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		flexWrap: 'wrap',
		// gap: '10px 10px',
		[BP1]: {
			flexDirection: 'column',
			gap: '10px 0px',
			alignItems: 'center',
		},
	},
	btnContainer: {
		width: '213px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '8px 30px',
		fontFamily: 'Open Sans',
		background: 'linear-gradient(102.89deg, #904DDC -14.44%, #7719A4 98.48%)',
		margin: '60px 0',
		order: '1',
		borderRadius: '10px',
		border: '1px solid rgba(45, 46, 50, 0.15)',
		backdropFilter: 'blur(12px)',
		'&:hover': {
			background: 'linear-gradient(102.89deg, #6632A2 -14.44%, #5A107D 98.48%)',
		},
		[BP1]: {
			order: 0,
			width: '80%',
			margin: '90px 0 30px 0',
			padding: '4px 15px',
		},
	},
	buttonCss: {
		fontSize: '16px',
		lineHeight: '24px',
		fontWeight: '800',
		textAlign: 'left',
		fontFamily: 'Open Sans',
		color: '#ffffff',
		textShadowColor: 'rgba(255, 255, 255, 0.25)',
		textShadowOffset: { width: 0, height: 0 },
		textShadowRadius: 8,
	},
	backgroundImage: {
		width: '100%',
	},
};
