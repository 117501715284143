import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { TextField } from '@mui/material';

export const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiFormHelperText-root, .Mui-error': {
    fontFamily: 'Open Sans',
    fontSize: '13px'
  },
  '& .MuiInputBase-root': {
    color: 'white',
  },
  '&::placeholder, label': {
    color: 'grey',
    fontFamily: 'Open Sans'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      color: 'white',
      borderColor: 'white',
      borderRadius: '10px',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      color: 'white',
      borderColor: 'white',
    },
    '&.MuiInputBase-input': {
      color: 'white'
    }
  },
});

export const SubmitButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',

  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
  },
});

export const sx = {
  submitButtonCss: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '800',
    padding: '12px 120px',
    textAlign: 'left',
    fontFamily: 'Open Sans',
    color: '#ffffff',
    textShadowColor: 'rgba(255, 255, 255, 0.25)',
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 8,
  },
  verifyButtonCss: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '800',
    padding: '12px 120px',
    textAlign: 'left',
    fontFamily: 'Open Sans',
    color: '#ffffff',
    textShadowColor: 'rgba(255, 255, 255, 0.25)',
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 8,
  },
  boxCss: {
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "65vh",
    width: "350px",
    marginTop: "12vh",
    marginBottom: "12vh",
    marginLeft: "auto",
    marginRight: "auto"
  },
  boxSuccessCss: {
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "20vh",
    width: "350px",
    marginTop: "12vh",
    marginBottom: "12vh",
    marginLeft: "auto",
    marginRight: "auto"
  },
  heading: {
    fontSize: '45px',
    fontStyle: 'normal',
    fontFamily: 'SchnyderM-Light-App',
    fontWeight: 'normal',
    lineHeight: '50px',
    letterSpacing: '-1px',
    textAlign: 'left',
    color: '#FFFFFF',
    textShadowColor: 'rgba(255, 255, 255, 0.55)',
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 57.321,
    textAlign: "center"
  },
  text: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontFamily: 'Open Sans',
    fontWeight: 'normal',
    textAlign: 'left',
    color: '#FFFFFF',
    textShadowColor: 'rgba(255, 255, 255, 0.55)',
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 57.321,
    textAlign: "center"
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Open Sans',
    background: 'linear-gradient(102.89deg, #904DDC -14.44%, #7719A4 98.48%)',
    order: '1',
    borderRadius: '10px',
    border: '1px solid rgba(45, 46, 50, 0.15)',
    backdropFilter: 'blur(12px)',
    '&:hover': {
      background: 'linear-gradient(102.89deg, #6632A2 -14.44%, #5A107D 98.48%)',
      cursor: 'pointer',
    },
  },
}