const INITIAL_STATE = {
	status: 'presale',
	items: 7777,
	sold: 2576,
	price: 0.3,
};

const info = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		default: return state;
	}
};

export default info;
