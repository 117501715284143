export const data = [
  {
    background: 'linear-gradient(179.99deg, #93B4BB 0.01%, #457582 99.99%)',
    text: 'Access to all 11 quests of the adventure - starting immediately and dropping weekly',
  },
  {
    background: 'linear-gradient(179.99deg, #90A0B7 0.01%, #606C8B 99.99%)',
    text: 'Animation of your evolving work of art, plus a still image of its final manifestation',
  },
  {
    background: 'linear-gradient(179.99deg, #A28EAE 0.01%, #615071 99.99%)',
    text: 'Dedicated private Discord rooms for each leg of the journey. Turn Betwixt into a shared journey and chat with the creators',
  },
  {
    background: 'linear-gradient(179.99deg, #68617E 0.01%, #3C374A 99.99%)',
    text: 'Behind-the-scenes access to extra resources such as self-reflection deep dives, guided meditations and other creative resiliency tools',
  },
  {
    background: 'linear-gradient(179.99deg, #7D6D83 0.01%, #A37D88 99.99%)',
    text: 'A chance to collaborate with us on the long term roadmap of Betwixt in the metaverse',
  },
]

export const heading = 'Epic experience and utility that Betwixt NFTs unlock';
export const subHeading = 'By owning a Betwixt NFT, you become part of a movement to build human resilience, both in the metaverse and beyond. This is the beginning of a bigger vision and an invitation to come dream with us, so we can make something epic together.'