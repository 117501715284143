import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const BP1 = '@media (max-width: 899px)';

export const JoinButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',

  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
  },
});

export const HeaderButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  px: '20px',
  pt: '10px',
  pb: '10px',
  '&:hover': {
    pointer: 'cursor',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
  },
  '&:focus': {
    boxShadow: 'none',
  },
  [BP1]: {
    display: 'none',
    px: '0px',
    pt: '0px',
    pb: '0px',
  }
})

export const sx = {
  root: {
    display: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    px: '7%',
    [BP1]: {
      px: '0%',
      pt: '5%',
      justifyContent: 'center',
    }
  },
  menuContainer: {
    width: '30%',
    display: 'flex',
    justifyContent: 'space-between',
    [BP1]: {
      width: '0%',
      display: 'none',
    }
  },
  menuButton: {
    fontFamily: 'Open Sans',
    fontWeight: '800',
    fontStyle: 'normal',
    color: '#ffffff',
    textAlign: 'center',
    fontSize: '16px',
    textShadowColor: 'rgba(255, 255, 255, 0.25)',
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 8,
  },
  rightMenuContainer: {
    width: '30%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [BP1]: {
      width: '0%',
      display: 'none',
    }
  },
  logoContainer: {
    textAlign: 'center',
    position: 'relative',
    [BP1]: {

    },
  },
  socialContainer: {
    [BP1]: {
      px: '0%',
      display: 'none',
    }
  },
  text: {
    fontSize: '42px',
    fontFamily: 'SchnyderM-Light-App',
    fontWeight: 500,
    letterSpacing: '1px',
    lineHeight: '50px',
    color: '#ffffff',
  },
  subText: {
    fontSize: '9px',
    letterSpacing: '1px',
    lineHeight: '11px',
    pt: '0%',
    color: '#ffffff',
    opacity: 0.6,
  },
  circle1: {
    position: 'absolute',
    width: '2px',
    height: '2px',
    top: '0%',
    left: '70%',
    background: '#FBF9FF',
  },
  circle2: {
    position: 'absolute',
    width: '2px',
    height: '2px',
    top: '73%',
    left: '33%',
    background: '#FBF9FF',
  },
  circle3: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    top: '20%',
    left: '25%',
    background: '#FBF9FF',
  },
  circle4: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    top: '70%',
    left: '-10%',
    background: '#FBF9FF',
  },
  circle5: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    top: '108%',
    left: '82%',
    background: '#FBF9FF',
  },
  buttonCss: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '800',
    textAlign: 'left',
    fontFamily: 'Open Sans',
    color: '#ffffff',
    textShadowColor: 'rgba(255, 255, 255, 0.25)',
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 8,
  },
  btnContainer: {
    width: '120px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 8px',
    fontFamily: 'Open Sans',
    background: 'linear-gradient(102.89deg, #904DDC -14.44%, #7719A4 98.48%)',
    order: '1',
    borderRadius: '10px',
    border: '1px solid rgba(45, 46, 50, 0.15)',
    backdropFilter: 'blur(12px)',
    '&:hover': {
      background: 'linear-gradient(102.89deg, #6632A2 -14.44%, #5A107D 98.48%)',
      cursor: 'pointer',
    },
    [BP1]: {
      margin: '60px 0',
      padding: '4px 15px',
    },
  },
}