import React from "react";
import { Box, Typography } from "@mui/material";
import { sx } from "./PrivacyCss";
import { text } from "./data";
import ReactHtmlParser from 'react-html-parser';

const Privacy = () => {
  return (
    <>
      <Box sx={sx.headingContainer}>
        <Typography sx={sx.heading}>Privacy Policy</Typography>
        <Typography sx={sx.subHeading}>
          last updated on 29 June 2022
        </Typography>
        <Box>
          <Typography sx={sx.policy}>
            <Box sx={sx.textBox}> {ReactHtmlParser(text)}</Box>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Privacy;
