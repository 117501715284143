import React from 'react';
import { Box } from '@mui/material';
import twitter from '../assets/images/social/twitter.svg';
import instagram from '../assets/images/social/instagram.svg';
import linkedin from '../assets/images/social/linkedin.svg';
import medium from '../assets/images/social/medium.svg';
import website from '../assets/images/social/website.svg';


const BP1 = '@media (max-width: 899px)';

const sx = {
	root: {
		width: '40px',
		height: '40px',
		borderRadius: '50%',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		[BP1]: {
			width: '20px',
			height: '20px',
		}
	},
};

const CONFIG = {
	twitter: {
		image: twitter,
	},
	instagram: {
		image: instagram,
	},
	linkedin: {
		image: linkedin,
	},
	medium: {
		image: medium,
	},
	website: {
		image: website,
	}
};

const ConnectButton = ({ variant, url }) => {
	const { image } = CONFIG[variant];

	return (
		<a href={url} target='_blank' rel='noopener noreferrer'>
			<Box sx={sx.root}>
				<img src={image} style={{ width: 24 }} alt={variant} />
			</Box>
		</a>
	);
};

// ConnectButton.propTypes = {
// 	variant: PropTypes.oneOf(['discord', 'twitter', 'instagram']).isRequired,
// };

export default ConnectButton;
