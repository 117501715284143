import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import discord from '../assets/images/social/discord.svg';
import twitter from '../assets/images/social/twitter.svg';
import instagram from '../assets/images/social/instagram.svg';

const BP1 = '@media (max-width: 899px)';

const sx = {
	root: {
		width: '40px',
		height: '40px',
		borderRadius: '50%',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		[BP1]: {
			width: '20px',
			height: '20px',
		}
	},
};

const CONFIG = {
	discord: {
		url: 'https://discord.com/invite/YM88YkMJ8J',
		image: discord,
	},
	twitter: {
		url: 'https://www.twitter.com/betwixtnft',
		image: twitter,
	},
	instagram: {
		url: 'https://google.com',
		image: instagram,
	},
};

const SocialButton = ({ variant }) => {
	const { url, image } = CONFIG[variant];
	return (
		<a href={url} target='_blank' rel='noopener noreferrer'>
			<Box sx={sx.root}>
				<img src={image} style={{ width: 24 }} alt={variant} />
			</Box>
		</a>
	);
};

SocialButton.propTypes = {
	variant: PropTypes.oneOf(['discord', 'twitter', 'instagram']).isRequired,
};

export default SocialButton;
