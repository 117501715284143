const BP1 = '@media (max-width: 899px)';

export const sx = {
	root: {
		width: '100%',
		pt: '8%',
		pb: '9%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	utilContainer: {
		mt: '80px',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	cardContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'center',
		width: '100%',
		img: {
			filter: 'saturate(0%)',
		},
		[BP1]: {
			flexDirection: 'column',
		},
	},
	heading: {
		fontFamily: 'SchnyderM-Light-App',
		fontWeight: 500,
	},
};
