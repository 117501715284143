export const data = [
  {
    background: '#432D5E',
    text1: 'Dr Sachin Shah and Dr Stephen Kaar',
    text2: '(Royal College of Psychiatrists)',
  },
  {
    background: '#35234B',
    text1: 'Dr Hanneke Scholten',
    text2: 'Behaviour researcher, co-director of the Games for Emotional and Mental Health Lab',
  },
  {
    background: '#2D1A44',
    text1: 'Dr Sarah Harmon',
    text2: 'Interdisciplinary scientist; human-computer interaction and computational creativity (Bowdoin College)',
  },
  {
    background: '#424E72',
    text1: 'Dr Charlie Hargood',
    text2: 'Narrative systems scholar (Bournemouth University)',
  },
  {
    background: '#224266',
    text1: 'Dr Lyle Skains',
    text2: 'Interactive narrative researcher (Bournemouth University)',
  },
  {
    background: '#20345E',
    text1: 'Dr Sofia Kitromili ',
    text2: 'Web science scholar (University of Southampton)',
  },
];

export const heading = 'External advisors and collaborators';