import React from "react";
import { Box, Typography } from "@mui/material";
import bannerImage from "../../../../assets/images/logo/bannerImage.gif";
import { sx, JoinButton } from "./BannerCss";

const Banner = ({ mintButtonClick }) => {
  return (
    <Box sx={sx.root}>
      <Box sx={sx.leftContainer}>
        <Box sx={sx.headingContainer}>
          <Typography sx={sx.heading}>
            Playable, immersive NFTs that help you befriend the voice in your
            head
          </Typography>
        </Box>
        <Box sx={sx.subHeadingContainer}>
          <Typography sx={sx.subHeading}>
            Betwixt is a play-to-thrive game with evolving generative art
            sculpted by your choices.
          </Typography>
        </Box>

        <Box sx={sx.btnContainer}>
          <JoinButton sx={sx.buttonCss} onClick={mintButtonClick}>
            Mint a Betwixt Elder
          </JoinButton>
        </Box>
        <Box sx={sx.subHeadingContainer}>
          <Typography sx={sx.subHeading2}>
            Or, play the first Dream of Betwixt for free <a href='https://betwixt-demo.firebaseapp.com/' target='_blank' rel='noopener noreferrer'>here</a>.
          </Typography>
        </Box>
        <Box sx={sx.policyContainer}>
          <a href="disclaimer" alt="view" style={{ textDecoration: "none" }}>
            <Typography sx={sx.policyText}>View disclaimer</Typography>
          </a>
          <a href="/privacy" alt="privacy" style={{ textDecoration: "none" }}>
            <Typography sx={sx.policyText}>Privacy policy</Typography>
          </a>
          <a href="/terms" alt="terms" style={{ textDecoration: "none" }}>
            <Typography sx={sx.policyText}>Terms and conditions</Typography>
          </a>
        </Box>
      </Box>
      <Box sx={sx.rightContainer}>
        <img
          src={bannerImage}
          style={{ width: "100%" }}
          alt="Betwixt Illustration"
        />
      </Box>
    </Box>
  );
};

/* eslint-disable react/forbid-prop-types */

export default Banner;
