const BP1 = '@media (max-width: 899px)';

export const sx = {
  card: {
    width: '312px',
    minHeight: '260px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '32px 16px',
    background: '#432D5E',
    borderRadius: '20px',
    textAlign: 'center',
    margin: '10px',
    [BP1]: {
      width: '100%',
      height: '0',
      minHeight: 'unset',
      margin: '4px 0',
    },
  },
};
