const BP1 = '@media (max-width: 899px)';

export const sx = {
	card: {
		width: '100%',
		maxWidth: '200px',
		minWidth: '200px',
		aspectRatio: '300 / 388',
		borderRadius: '24px',
		padding: '36px 12px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		position: 'relative',
		'&:hover': {
			transform: 'scale(1.1)',
			transition: '0.5s',
		},
		'& + &': {
			ml: '50px',
		},
		[BP1]: {
			'& + &': {
				ml: '20px',
			},
		},
	},
	rectangle: {
		width: '5px',
		height: '5px',
		position: 'absolute',
		left: '50%',
		right: '16.92%',
		top: '15%',
		bottom: '38.79%',
		background: 'white',
		boxShadow: '0px 2.93449px 8.80347px rgba(255, 255, 255, 0.35)',
		transform: 'rotate(45deg)',
	},
	text: {
		pt: '20%',
		fontSize: '33.76px',
		fontStyle: 'normal',
		fontFamily: 'SchnyderM-Light-App',
		fontWeight: 'normal',
		lineHeight: '110%',
		letterSpacing: '-0.7px',
		textAlign: 'center',
		color: '#ffffff',
		textShadowColor: 'rgba(255, 255, 255, 0.45)',
		textShadowOffset: { width: 0, height: 0 },
		textShadowRadius: 26,
	},
	subText: {
		px: '10%',
		pt: '10%',
		fontSize: '9.64px',
		fontStyle: 'normal',
		fontFamily: 'Jotia-Light',
		fontWeight: 'normal',
		lineHeight: '140%',
		textAlign: 'center',
		color: '#ffffff',
		opacity: 0.6,
	},
	extraSubText: {
		px: '10%',
		pt: '5%',
		fontSize: '9.64px',
		fontStyle: 'normal',
		fontFamily: 'Jotia-Light',
		fontWeight: 'normal',
		lineHeight: '140%',
		textAlign: 'center',
		color: '#ffffff',
		opacity: 0.6,
	},
};
