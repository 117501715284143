import React, { forwardRef } from 'react';
import { sx, JoinButton } from './WalletCss';
import { Box, Typography } from '@mui/material';
import Card from './components/Card/Card';
import { data, heading, subHeading } from './Data/Data';

const Wallet = forwardRef((props, ref) => (
	<Box id="utility" sx={sx.root} ref={ref}>
		<Box sx={sx.headingContainer}>
			<Typography sx={sx.heading} color='background.light' variant='heading1' align='center' >
				{heading}
			</Typography>
			<Typography sx={sx.subHeading} color='rgba(255, 255, 255, 0.6)' fontFamily='Open Sans' align='center'>
				{subHeading}
			</Typography>
		</Box>
		<Box sx={sx.cardContainer}>
			{
				data.map((card) => (
					<Card key={card.text} background={card.background} text={card.text} />
				))
			}
		</Box>
		<Box sx={sx.btnContainer}>
			<JoinButton sx={sx.buttonCss} onClick={props.discordButtonClick}>Follow on Discord</JoinButton>
		</Box>
	</Box>
));

Wallet.displayName = 'Wallet';

export default Wallet;
