import React, {
	forwardRef, useRef, useEffect, useState,
} from 'react';
import { Box, Typography } from '@mui/material';
import { sx } from './FeaturesCss';
import Cards from './components/Cards/Cards.jsx';
import { data, heading, footerText } from './Data/cardData';

const Features = forwardRef((props, ref) => {
	const cardContainerRef = useRef(null);
	const [isScrollVisible, setIsScrollVisible] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			if (cardContainerRef.current) {
				if (cardContainerRef.current.scrollWidth > cardContainerRef.current.clientWidth) {
					setIsScrollVisible(true);
				} else {
					setIsScrollVisible(false);
				}
			}
		};
		window.addEventListener('resize', handleResize);
		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [cardContainerRef]);

	return (
		<Box id="about" sx={sx.root}>
			<Box sx={sx.rectangle} />
			<Box sx={sx.headingContainer}>
				<Typography
					color='background.light'
					variant='heading1'
					align='center'
					ref={ref}
					sx={sx.heading}
				>
					{heading}
				</Typography>
			</Box>
			<Box sx={sx.cardContainer} ref={cardContainerRef} style={isScrollVisible ? { justifyContent: 'normal' } : null}>
				{
					data.map((card) => (
						<Cards
							key={card.mainText}
							background={card.background}
							mainText={card.mainText}
							subText={card.subText}
							extraSubText={card.extraSubText}
						/>
					))
				}
			</Box>
			<Box sx={sx.footerContainer}>
				<Typography
					sx={sx.footerHeading}
				>
					{footerText}
				</Typography>
			</Box>
		</Box>
	);
},
);

Features.displayName = 'Features';

export default Features;
