export const text = `

<b>Overview</b>
<br>
The following terms and conditions constitute a legally binding agreement (this “Agreement” or the “Terms”) between you (“you”, “your”, or “user”) and Mind Monsters Games Ltd as the creator of the Betwixt NFT project (“Betwixt NFT”, “we”, “us”, or “our”). These Terms apply to your interaction with the Betwixt NFT Elder Mint, our first NFT collection, including your access to and use of this Site, as well as all content, utility and functionality offered as part of owning a Betwixt NFT (collectively, the “Services”).
<br>
By accessing or using any part of this site or engaging with the project by participating in the minting, purchasing, or sale of a Betwixt NFT (either directly from us or on resale), you acknowledge that you understand this Agreement and accept all of its terms. 

We reserve the right to change or modify portions of these Terms at any time. The date revisions are made will be marked at the top of this page and changes will apply on that date. Your continued access to and use of the Site and Services will constitute your binding acceptance of the updates.

<br>
<br>
<b>Betwixt NFT project</b>
<br>
A Betwixt NFT is a non-fungible Ethereum-based token that uses smart contracts on the Ethereum blockchain. Owning a Betwixt NFT grants you access to a mobile app (the “App”) that combines an interactive story, sound and generative art into a guided reflective experience. While you engage with the app, a generative visual forms itself and evolves using as inputs your choices. The final iteration of this visual will be embodied into your NFT.
<br>
<br>
<b>Disclaimer</b>
<br>
The App may cause you to confront emotionally sensitive areas of your life, but Betwixt is not a form of therapy nor a replacement for medical or therapeutic treatment, and no human will be monitoring your conversations. If you are concerned about your mental health, please seek a qualified health professional's advice before using the App and before making any medical decisions.
<br>
The story contained in the Betwixt App is in the English language. You need to be able to speak English in order to play. 


<br>
<br>
<b>License Terms </b>
<br>

1. General IP rights
<br>
Through your purchase of a Betwixt NFT (the “Licensed NFT”) which contains and embodies artwork by Mind Monsters Games Ltd (the “Art”), you will be granted a <b>non-exclusive, worldwide, royalty-free, non-transferable, non-sublicensable license </b> to:
<br>
1.1 use, copy or display the Art of your Licensed NFT for your own personal use; 
<br>
1.2 sell or transfer your Licensed NFTs to another party pursuant to these Terms; 
<br>
1.3 exploit or commercialize the Art contained in your Licensed NFT by producing and selling physical merchandise that portrays the Art in its entirety; and 
<br>
1.4 use your Licensed NFT as part of a third-party website or application which permits the inclusion, involvement, and/or participation for your Licensed NFTs, provided that the website or application cryptographically permits and verifies each NFT owner’s rights and ownership to display the Art and the website or application ensures only the actual owner can display the Art. 

For the avoidance of doubt, the Art refers to the final image produced after your completion of the App and saved into the NFT. The license above does not extend to intermediate images generated throughout your journey in the App. 


<br>
<b>2. Termination on sale and transfer</b>
<br>
You understand and agree that these rights are licensed solely to the rightful and legal Owner of the Licensed NFT. Your licensed rights to the Licensed NFT will terminate upon the rightful and legal sale or transfer of your Licensed NFT.
<br>
<b>3. Restricted use</b>
<br>
3.1. You will not have the right to use the Betwixt brand, logo, or other intellectual property rights other than the Art in the above stated activities.
<br>
3.2. You may not assert or imply that you are, or that your use of the Art is sponsored or endorsed by us. You may not falsely represent that you are the creator of the Art.


3.3. You may not use the Art is such a way that brings us and the Betwixt brand or project into public disrepute, contempt, scandal, or ridicule, or which reflects unfavorably upon our image. 

Failure to comply with the above may result in termination of your right to commercialize or exploit the Art. 
<br>
<br>
<b>Sale of Betwixt NFT</b>
<br>
If you decide to sell a Betwixt NFT (“Secondary Sale”), we bear no responsibility for any transactions between you and a third party, including using any NFT Trading Platform to transfer your Licensed NFTs.

We will receive 7.0% of every Secondary Sale of a Betwixt NFT (“Royalty”). 


It is your responsibility to make sure that the transferee or purchaser understands and accepts the Terms set forth here. As such, if you sell a Betwixt NFT on a third-party marketplace, you may include a statement similar to the following in the description of the NFT: “7.0% Royalty Applies. By purchasing a Betwixt NFT, you agree to the Terms located at https://nft.betwixt.life”.
<br>
<br>
<b>Taxes</b>
<br>
You are responsible for any and all sales, use, value-added and other taxes, duties, and assessments now or hereafter claimed or imposed by any governmental authority, associated with your use of the Site and Services.

<br>
<br>
<b>Assumption of Risk </b>
<br>
You accept and acknowledge each of the following:

You assume all risks associated with the purchase and sale of our NFTs, including any volatility associated with the price of our NFTs or Ethereum, as well as all risks associated with using an internet-based currency (issues with hardware, software, internet connection, and unauthorized access to your Ethereum wallet).

No information on our website is or may be considered to be advice or an invitation to enter into an agreement for any investment purpose. Nothing on this website is intended to be an offering of any securities in any jurisdiction nor does it constitute an offer or invitation to purchase shares (including in our company), securities, or other financial instruments. 

You assume all responsibility for any adverse effects of disruptions or other issues impacting Ethereum or the Ethereum network.

<br>
<br>
<b>Disclaimers</b>
<br>
You expressly understand and agree that your access to and use of the Site and participation in the Project is at your sole risk, and that the Site is provided “as is” and “as available” without warranties of any kind, whether express or implied. To the fullest extent permissible pursuant to applicable law, we make no express warranties and hereby disclaim all implied warranties regarding the Site, Discord and/or the Project as a whole, and any part of each (including, without limitation, the site, any smart contract, or any external websites), including the implied warranties of merchantability, fitness for a particular purpose, non-infringement, correctness, accuracy, or reliability. 
<br>
Without limiting the generality of the foregoing, we, our subsidiaries, affiliates, and licensors do not represent or warrant to you that: (I) your access to or use of the Site and Services will meet your requirements, (ii) your access to or use of the Site and Services will be uninterrupted, timely, secure or free from error, (iii) the Site or any content or features made available on or through the site and/or the Discord are free of viruses or other harmful components, or (iv) that any data that you disclose when you use the site and/or Discord will be secure. Some jurisdictions do not allow the exclusion of implied warranties in contracts with consumers, so some or all of the above exclusions may not apply to you.

<br>
You accept the inherent security risks of providing information and dealing online over the internet and agree that we have no liability or responsibility for any breach of security unless it is due to our willful misconduct.


We will not be responsible or liable to you for any losses you incur as the result of your use of the Ethereum network nor do we have any control over, and make no guarantees regarding, any smart contracts.

<br>
<br>
<b>Limitation of Liability</b>
<br>
You understand and agree that we will not be liable to you or to any third party for any indirect, incidental, special, consequential, punitive or exemplary damages which you may incur, howsoever caused and under any theory of liability, including, without limitation, any loss of profits (whether incurred directly or indirectly), loss of goodwill or business reputation, loss of data, cost of procurement of substitute goods or services, or any other intangible loss, even if we have been advised of the possibility of such damages.

<br>
You agree that our total, aggregate liability to you for any and all claims arising out of or relating to this agreement or your access to or use of (or your inability to access or use) any portion of the Site or Services, whether in contract, tort, strict liability, or any other legal theory, is limited to the greater of (a) the amounts you actually paid us under this agreement in the 12 month period preceding the date the claim arose, or (b) $500.

<br>
We will not be responsible or liable to you or any other party for errors or failures to execute any purchase of your NFT, including, without limitation, errors or failures caused by: (I) your failure to follow our instructions; (ii) any loss of connection to our Site unless caused by our gross negligence; (iii) a failure of any software or device used by you to purchase your  NFT; or (iv) for any other failure to execute your NFT purchase or for errors or omissions in connection with this activity unless caused by our gross negligence. 
<br>
<br>
<b>Indemnification</b>
<br>
You agree to indemnify, defend and hold harmless us, our employees, contractors, service providers, consultants, successor organizations, and each of their respective officers, directors, agents, partners and employees (individually and collectively, the “Indemnified Parties”) from and against any losses, liabilities, claims, demands, damages, expenses or costs (“Claims”) arising out of or related to (i) your breach of this Agreement, (ii) your misuse of the Site or Services, (iii) your violation of any intellectual property right or other rights of another party, and/or (iv) your violation of Applicable Laws, rules or regulations in connection with your access to or use of the Site or Services.  This indemnity shall be applicable without regard to the negligence of any party, including any indemnified person, and shall survive any termination of this Agreement.


To the maximum extent permitted by Applicable Law, you hereby irrevocably and unconditionally release and waive all claims against any Indemnified Parties from any Claims arising out of or in any way connected with your use of the Site or purchase of a Betwixt NFT. To the extent that you do have any claims against us, you agree that: (i) any and all disputes, claims and causes of action will be resolved individually, without resort to any form of class action; and (ii) any and all claims, judgments and awards shall be limited to actual and documented damages and out-of-pocket costs incurred, but shall in no event include attorneys’ fees or litigation costs (e.g., court filing fees and related costs).
<br>
<br>
<b>Severability</b>
<br>
If any term or provision of this Agreement is deemed invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision of this Agreement or invalidate or render unenforceable such term or provision in any other jurisdiction.
<br>
<br>
<b>Governing Law</b>
<br>
The Terms are governed by English law and the English or Welsh courts will have exclusive jurisdiction to settle any dispute (including non-contractual disputes) arising out of or in connection with our Agreement.`
