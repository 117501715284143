import React from "react";
import { Box, Button, Typography } from '@mui/material';
import { sx } from './ArtCss';
import { heading, footerHeading } from './Data/Data';
import artImage from '../../../../assets/images/logo/artImage.gif';

const Art = () => {
  return (
    <Box sx={sx.root}>
      <Box sx={sx.rectangle} />
        <Typography
          color='background.light' 
          sx={sx.heading} 
        >
          {heading}
        </Typography>
      <Box sx={sx.footerHeadingContainer}>
        <Typography 
          color='rgba(255, 255, 255, 0.6)'  
          sx={sx.footerHeading} 
          >
             {footerHeading}
        </Typography>
      </Box>
      <Box sx={sx.imageContainer}>
        <img style={sx.imageCss} src={artImage} alt='art-image' />
      </Box>
    </Box>
  );
}

export default Art;