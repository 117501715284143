import React from "react";
import {sx} from "./CardCss";
import { Box, Typography } from '@mui/material';

const Card = ({background, text}) => {
  return (
    <Box sx={{...sx.card, background: `${background}`}}>
      <Box sx={sx.rectangle} />
      <Typography sx={sx.cardText}>{text}</Typography>
    </Box>
  );
}

export default Card;