const BP1 = '@media (max-width: 899px)';

export const sx = {
	card: {
		width: '232px',
		height: '300px',
		borderRadius: '24px',
		padding: '7% 7% 5% 7%',
		margin: '4px',
		px: '0.5%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		position: 'relative',
		[BP1]: {
			width: '100%',
			height: 'auto',
			pt: '20%',
			pb: '10%',
			px: '5%',
		},
	},
	rectangle: {
		width: '8px',
		height: '8px',
		position: 'absolute',
		left: '50%',
		right: '16.92%',
		top: '15%',
		bottom: '38.79%',
		background: 'white',
		boxShadow: '0px 2.93449px 8.80347px rgba(255, 255, 255, 0.35)',
		transform: 'rotate(45deg)',
	},
	cardText: {
		fontWeight: 300,
		fontSize: '18px',
		lineHeight: '24px',
		textAlign: 'center',
		color: '#ffffff',
	},
};
