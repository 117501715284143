import { environment } from "../environment";
export var firebaseConfig = {};

if (environment == 'prod') {
  firebaseConfig = {
    apiKey: "AIzaSyBIA2kEWytffqPOOTq94muRC0rbLDHYLiY",
    authDomain: "betwixt-game.firebaseapp.com",
    databaseURL: "https://betwixt-game.firebaseio.com",
    projectId: "betwixt-game",
    storageBucket: "betwixt-game.appspot.com",
    messagingSenderId: "380309688009",
    appId: "1:380309688009:web:54b69e333d2fd6814fc718",
    measurementId: "G-Z0Z4M02911"
  }
}
else if (environment == 'test') {
  firebaseConfig = {
    apiKey: "AIzaSyBLpbzQC2iC0xZEMyKzbVr6IOD-RvsQIKo",
    authDomain: "betwixt-dev.firebaseapp.com",
    projectId: "betwixt-dev",
    storageBucket: "betwixt-dev.appspot.com",
    messagingSenderId: "420421791017",
    appId: "1:420421791017:web:cc1025123abab4ee0d9b38",
    measurementId: "G-S57GY9HVRG"
  }
}
