import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const JoinButton = styled(Button)({
	boxShadow: 'none',
	textTransform: 'none',

	'&:active': {
		boxShadow: 'none',
		backgroundColor: '#0062cc',
	},
});

export const PolicyButton = styled(Button)({
	boxShadow: 'none',
	textTransform: 'none',
	px: '20px',
	pt: '10px',
	pb: '10px',
	'&:hover': {
		pointer: 'cursor',
		boxShadow: 'none',
	},
	'&:active': {
		boxShadow: 'none',
		backgroundColor: '#0062cc',
	},
	'&:focus': {
		boxShadow: 'none',
	},
})

const BP1 = '@media (max-width: 899px)';

export const sx = {
	root: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		pt: '8%',
		pb: '8%',
		pl: '7%',
		pr: '7%',
		marginBottom: '120px',
		[BP1]: {
			flexDirection: 'column-reverse',
			justifyContent: 'center',
			gap: '2%',
			pb: 0,
			pl: 0,
			pr: 0,
			marginBottom: '60px',

		},
	},
	leftContainer: {
		width: '50%',
		maxWidth: '500px',
		pt: '5%',
		pb: '5%',
		[BP1]: {
			width: '100%',
			padding: '34px 5%',
			display: 'block',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		},
	},
	rightContainer: {
		width: '42%',
		maxWidth: '585px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '2%',
		background: '#0E0E0E',
		borderRadius: '32px',
		[BP1]: {
			width: '100%',
			maxWidth: '100%',
		},
	},
	btnContainer: {
		width: '260px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '8px 30px',
		fontFamily: 'Open Sans',
		background: 'linear-gradient(102.89deg, #904DDC -14.44%, #7719A4 98.48%)',
		marginTop: '30px',
		order: '1',
		borderRadius: '10px',
		border: '1px solid rgba(45, 46, 50, 0.15)',
		backdropFilter: 'blur(12px)',
		'&:hover': {
			background: 'linear-gradient(102.89deg, #6632A2 -14.44%, #5A107D 98.48%)',
			cursor: 'pointer',
		},
		[BP1]: {
			margin: '60px auto',
			padding: '4px 15px',
			marginBottom: '30px',
		},
	},
	buttonCss: {
		fontSize: '16px',
		lineHeight: '24px',
		fontWeight: '800',
		textAlign: 'left',
		fontFamily: 'Open Sans',
		color: '#ffffff',
		textShadowColor: 'rgba(255, 255, 255, 0.25)',
		textShadowOffset: { width: 0, height: 0 },
		textShadowRadius: 8,
	},
	heading: {
		fontSize: '42px',
		fontStyle: 'normal',
		fontFamily: 'SchnyderM-Light-App',
		fontWeight: 'normal',
		lineHeight: '50px',
		letterSpacing: '4px',
		textAlign: 'left',
		color: '#FFFFFF',
		textShadowColor: 'rgba(255, 255, 255, 0.55)',
		textShadowOffset: { width: 0, height: 0 },
		textShadowRadius: 57.321,
		[BP1]: {
			fontSize: '32px',
			lineHeight: '38px',
		},
	},
	subHeadingContainer: {
		flex: 'none',
		order: 1,
		flexGrow: 0,
		margin: '20px 0px',
	},
	policyText: {
		fontSize: '14px',
		lineHeight: '24px',
		fontWeight: '800',
		textAlign: 'left',
		fontFamily: 'Open Sans',
		color: 'rgba(255, 255, 255, 0.6)',
		textShadowColor: 'rgba(255, 255, 255, 0.25)',
		textShadowOffset: { width: 0, height: 0 },
		textShadowRadius: 8,
		textDecoration: 'none',
		'&:hover': {
			pointer: 'cursor',
			boxShadow: 'none',
		},
		[BP1]: {
			fontSize: '12px',
			textAlign: 'center'
		},
	},
	policyContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '70%',
		marginTop: '10px',
		[BP1]: {
			width: '100%',
			marginTop: '20px',
			display: 'block',
			martinTop: '0'
		},
	},
	subHeading: {
		fontSize: '16px',
		lineHeight: '24px',
		fontWeight: '800',
		textAlign: 'left',
		fontFamily: 'Open Sans',
		color: 'rgba(255, 255, 255, 0.6)',
		textShadowColor: 'rgba(255, 255, 255, 0.25)',
		textShadowOffset: { width: 0, height: 0 },
		textShadowRadius: 8,
		a: {
			color: '#8659A5',
		},
		[BP1]: {
			fontSize: '15px',
			mt: '15px',
		},
	},
	subHeading2: {
		fontSize: '16px',
		lineHeight: '24px',
		fontWeight: '800',
		textAlign: 'left',
		fontFamily: 'Open Sans',
		color: 'rgba(255, 255, 255, 0.6)',
		textShadowColor: 'rgba(255, 255, 255, 0.25)',
		textShadowOffset: { width: 0, height: 0 },
		textShadowRadius: 8,
		a: {
			color: '#8659A5',
		},
		[BP1]: {
			fontSize: '15px',
			mt: '15px',
			textAlign: 'center'
		},
	}
};
