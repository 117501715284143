import React from 'react';
import { Box, Typography } from '@mui/material';
import { sx } from './disclaimerCss';
import { text } from './data';


const Disclaimer = () => {
  return (
    <>
      <Box sx={sx.headingContainer}>
        <Typography
          sx={sx.heading}
        >
          Disclaimer
      </Typography>
        <Typography
          sx={sx.subHeading}
        >
          last updated on May 2022
      </Typography>
        <Typography
          sx={sx.policy}
        >
          {text}
        </Typography>
      </Box>
    </>
  );
}

export default Disclaimer;