/* eslint-disable no-console */
import axios from 'axios';

const api = () => {
	const tmp = axios.create({
		baseURL: 'https://self-service-platform-staging.herokuapp.com',
		// baseURL: 'http://192.168.1.5:3100/',
		timeout: 5000,
	});
	tmp.defaults.headers.get.Accept = 'application/json';
	tmp.interceptors.request.use(
		(request) => request,
		(error) => {
			if (error.response) {
				console.info('REQUEST ERROR');
				console.log(JSON.stringify(error.response, null, 2));
			}
			return Promise.reject(error);
		},
	);
	tmp.interceptors.response.use(
		// eslint-disable-next-line arrow-body-style
		(response) => {
			// console.info('RESPONSE');
			// console.info(JSON.stringify(response, null, 2));
			return response;
		},
		(error) => {
			console.info('RESPONSE ERROR');
			console.log(JSON.stringify(error, null, 2));
			if (error.response?.data?.error) {
				return Promise.reject(error.response.data.error);
			}
			return Promise.reject(error);
		},
	);
	return tmp;
};

export default api;
