const BP1 = '@media (max-width: 1311px)';

export const sx = {
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		pt: '9%',
		[BP1]: {
			pb: '60px',
			mt: '60px',
		},
	},
	rectangle: {
		width: '8px',
		height: '8px',
		position: 'absolute',
		left: '50%',
		right: '16.92%',
		top: '8%',
		bottom: '38.79%',
		background: 'white',
		boxShadow: '0px 2.93449px 8.80347px rgba(255, 255, 255, 0.35)',
		transform: 'rotate(45deg)',
		[BP1]: {
			top: '0%',
		},
	},
	headingContainer: {
		flex: 'none',
		order: 1,
		flexGrow: 0,
		margin: '20px 0px',
	},
	heading: {
		fontSize: '42px',
		fontStyle: 'normal',
		fontFamily: 'SchnyderM-Light-App',
		fontWeight: 'normal',
		lineHeight: '50px',
		letterSpacing: '4px',
		textAlign: 'center',
		color: '#FFFFFF',
		textShadowColor: 'rgba(255, 255, 255, 0.55)',
		textShadowOffset: { width: 0, height: 0 },
		textShadowRadius: 57.321,
		[BP1]: {
			fontSize: '32px',
			lineHeight: '38px',
		},
	},
	subHeadingContainer: {
		flex: 'none',
		order: 1,
		flexGrow: 0,
		margin: '20px 0px',
	},
	footerHeadingContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		px: '22%',
		pt: '3%',
		[BP1]: {
			px: '5%',
		},
	},
	footerHeading: {
		fontSize: '16px',
		lineHeight: '24px',
		fontWeight: '800',
		textAlign: 'center',
		fontFamily: 'Open Sans',
		color: 'rgba(255, 255, 255, 0.6)',
		textShadowColor: 'rgba(255, 255, 255, 0.25)',
		textShadowOffset: { width: 0, height: 0 },
		textShadowRadius: 8,
		[BP1]: {
			fontSize: '15px',
			mt: '15px',
		},
	},
	imageContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		pt: '2%',
		[BP1]: {
			pt: '20%',
		},
	},
	imageCss: {
		width: '100%',
	},
};
