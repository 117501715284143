import React, { createRef } from 'react';
import Home from './Home/Home';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import MainHeader from '../components/MainHeader';
import Footer from '../components/Footer';
import Privacy from './privacy/Privacy';
import Terms from './terms&conditions/Terms';
import Disclaimer from './disclaimer/Disclaimer';
import Register from './register/Register'
import NFTConnect from './nft-connect/NFTConnect'

const Views = () => {
  const aboutRef = createRef();
  const utilityRef = createRef();
  const teamRef = createRef();

  const discordButtonClick = () => {
    window.open(
      'https://discord.com/invite/YM88YkMJ8J',
      '_blank',
    )
  }

  const mintButtonClick = () => {
    window.open(
      'https://galaxis.xyz/#/projects/betwixt-nft',
      '_blank',
    )
  }

  return (
    <>
      <MainHeader teamRef={teamRef} aboutRef={aboutRef} utilityRef={utilityRef} discordButtonClick={discordButtonClick} mintButtonClick={mintButtonClick} />
      <Router>
        <Switch>
          <Route
            path={'/'}
            exact={true}
            render={() =>
              <Home
                teamRef={teamRef}
                aboutRef={aboutRef}
                utilityRef={utilityRef}
                discordButtonClick={discordButtonClick}
                mintButtonClick={mintButtonClick}
              />
            }
          />
          <Route
            path='/privacy'
            exact={true}
            render={() =>
              <Privacy />
            }
          />
          <Route
            path='/terms'
            exact={true}
            render={() =>
              <Terms />
            }
          />
          <Route
            path='/disclaimer'
            exact={true}
            render={() =>
              <Disclaimer />
            }
          />
          <Route
            path='/register'
            exact={true}
            render={() =>
              <Register />
            }
          />
          <Route
            path='/nft-connect'
            exact={true}
            render={() =>
              <NFTConnect />
            }
          />
          <Route exact path="*" render={() => (
            <Redirect to="/" />
          )} />
        </Switch>
      </Router>
      <Footer teamRef={teamRef} aboutRef={aboutRef} utilityRef={utilityRef} />
    </>
  );
}

export default Views;