import React from 'react';
import { Box, Typography } from '@mui/material';
import { sx } from './TeamCardCss';
import ConnectButtons from '../../../../../components/ConnectButtons';

const TeamCard = ({
	card, image, name, surname, post, text,
}) => (
	<Box sx={sx.card}>
		<Box sx={sx.wrapper}>
			<Box sx={sx.imageContainer}>
				<img src={image} alt={`${name} ${surname}`} />
			</Box>
			<Typography
				sx={sx.headerContainer}
			>
				<Box sx={sx.fullNameContainer}>
					<Typography
						sx={sx.nameContainer}
					>
						{name}
					</Typography>
					<Typography
						sx={sx.nameContainer}
					>
						{surname}
					</Typography>
				</Box>
				<Typography
					style={{
						padding: '5px 0px', fontFamily: 'Jotia-Light', fontSize: '16px', textTransform: 'none',
					}}
					color='background.light'
					variant='caption'
					align='left'
				>
					{post}
				</Typography>
			</Typography>
		</Box>

		<Typography
			sx={sx.introContainer}
		>
			{text}
		</Typography>
		{
			card.subTextWithLinks && (
				card.subTextWithLinks.map((textObj) => (
					<Box style={{ display: 'flex' }} mb={1} key={textObj.text}>
						<Typography
							color='rgba(255, 255, 255, 0.6)'
							sx={{ ...sx.text, mt: '8px' }}
							fontFamily='Open Sans'
						>
							<a
								href={textObj.link}
								key={text}
								target='_blank'
								style={{
									textDecorationColor: 'rgba(255, 255, 255, 0.6)',
									color: 'rgba(255, 255, 255, 0.6)',
								}}
								rel='noreferrer'
							>
								{textObj.text}
							</a>
							{textObj.subtext}
						</Typography>

					</Box>
				))
			)
		}
		<Box sx={sx.socialConnect}>
			{
				card.linkedin && <ConnectButtons variant='linkedin' url={card.linkedin} />
			}
			{
				card.instagram && <ConnectButtons variant='instagram' url={card.instagram} />
			}
			{
				card.twitter && <ConnectButtons variant='twitter' url={card.twitter} />
			}
			{
				card.medium && <ConnectButtons variant='medium' url={card.medium} />
			}
			{
				card.website && <ConnectButtons variant='website' url={card.website} />
			}
		</Box>
	</Box>
);

export default TeamCard;
