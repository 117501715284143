import hazel from '../../../../../assets/images/team/hazel.jpg';
import elitsa from '../../../../../assets/images/team/elitsa.jpg';
import katalina from '../../../../../assets/images/team/katalina.jpg';
import natalia from '../../../../../assets/images/team/natalia.jpg';
import gaurav from '../../../../../assets/images/team/gaurav2.jpg';
import nik from '../../../../../assets/images/team/nik.jpeg';
import nikolav from '../../../../../assets/images/team/nikolay.jpg';
import holger from '../../../../../assets/images/team/holger.jpg';

export const data = [
  {
    image: hazel,
    name: 'HAZEL',
    surname: 'GALE',
    post: 'CO-CEO',
    text: 'Therapist of 10+ years, internationally published author, ex-world kickboxing champion. Master of board games. ',
    linkedin: 'https://www.linkedin.com/in/hazelgale/',
    medium: 'https://hazelgale.medium.com/',
    subTextWithLinks: [
      {
        text: 'Interview with Gamesindustry.biz',
        link: 'https://www.gamesindustry.biz/articles/2021-11-19-meet-the-therapist-making-a-therapeutic-psychedelic-trip-through-your-smartphone',
      },
      {
        text: 'Hazel’s book The Mind Monster Solution on Amazon',
        link: 'https://www.amazon.com/Fight-Win-Freedom-From-Self-Sabotage/dp/147366246X/ref=mp_s_a_1_2?crid=17SACFIR7EZD1&keywords=the+mind+monster+solution&qid=1639123823&sprefix=the+mind+monster+solution%2Caps%2C139&sr=8-2',
      },
    ],
  },
  {
    image: elitsa,
    name: 'ELITSA',
    surname: 'DERMENDZHIYSKA',
    post: 'CO-CEO',
    text: 'Second-time founder who left work to study mental health in 2016. Science writer (Aeon, Guardian), existentialist.',
    linkedin: 'https://www.linkedin.com/in/elitsadermendzhiyska/',
    medium: 'https://medium.com/@Ellie_',
    subTextWithLinks: [
      {
        text: 'On attachment styles, therapy and change ',
        subtext: '- Guardian, Jan 2020',
        link: 'https://www.theguardian.com/science/2020/jan/10/psychotherapy-childhood-mental-health',
      },
      {
        text: 'Emotions aren’t universal but specific to each culture ',
        subtext: '- Aeon, Oct 2021',
        link: 'https://aeon.co/essays/what-if-emotions-arent-universal-but-specific-to-each-culture',
      },
    ],
  },
  // {
  //   image: natalia,
  //   name: 'NATALIA',
  //   surname: 'THEODORIDOU',
  //   post: 'Chief storyteller',
  //   text: 'World Fantasy Award-winning author and Nebula-nominated game designer.',
  //   twitter: 'https://twitter.com/natalia_theodor',
  //   website: 'https://www.natalia-theodoridou.com/',
  // },
  {
    image: gaurav,
    name: 'GAURAV',
    surname: 'MITTAL',
    post: 'Software dev and competitive gamer',
    text: 'Former rank #1 in WoW, top 50 Hearthstone player globally.',
    linkedin: 'https://www.linkedin.com/in/gauravmittal15/',
  },
  {
    image: katalina,
    name: 'KATALINA',
    surname: 'DIMITROVA',
    post: 'Software dev',
    text: "Frontend queen, product geek, tech enthusiast, gamer.",
    linkedin: 'https://www.linkedin.com/in/katalinadimitrova/',
  },
  {
    image: nikolav,
    name: 'NIKOLAY',
    surname: 'BABEV',
    post: 'Software dev',
    text: "Compulsive tinkerer and fixer of things who likes to travel and play basketball.",
    linkedin: 'https://www.linkedin.com/in/nikolay-babev-12288311b/',
  },
  {
    image: nik,
    name: 'NIKOLAI',
    surname: 'CEDRAEUS',
    post: 'Musician',
    text: "Classically trained composer with 15+ years of music composition and production experience. Lawyer by day.",
    instagram: 'https://www.instagram.com/nikolaicedraeus/',
    website: 'https://www.cedraeus.co.uk/',
  },
  {
    image: holger,
    name: 'HOLGER',
    surname: 'LIPPMANN',
    post: 'Generative Artist',
    text: "A pioneer of 'generative painting', in which software produces a continuous stream of new aesthetic creations.",
    linkedin: 'https://www.linkedin.com/in/holger-lippmann-6052a712/',
    website: 'http://e-art.co',
  },
];
