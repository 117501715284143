import React from "react";
import { Box, Button, Typography } from '@mui/material';
import {sx} from './CardCss';

const Card = ({background, text1, text2}) => (
  <Box sx={{...sx.card, background: `${background}`}}>
    <Typography color='background.light' sx={{pt: '10px', pb: '10px'}}>
      {text1}
    </Typography>
    <Typography color='rgba(255, 255, 255, 0.6)'>
      {text2}
    </Typography>
  </Box>
)

export default Card;