import React from "react";
import { Box, Typography } from '@mui/material';
import { sx } from './CardsCss';

const Cards = ({background, mainText, subText, extraSubText}) => {

  return (
  <Box sx={sx.card} style={{background: `${background}`}}>
    <Box sx={sx.rectangle} />
    <Typography 
			sx={sx.text}
    >
      {mainText}
    </Typography>
    <Typography 
			sx={sx.subText}
    >
      {subText}
    </Typography>
		{extraSubText && (
			<Typography 
				color='background.light' 
				variant='h6' 
				align='center'
				sx={sx.extraSubText}
			>
				{extraSubText}
			</Typography>
		)}
  </Box>
  );
}

export default Cards;