
import React, { createRef, useState } from 'react';
import RegistrationForm from '../Home/components/Registration/Registration';

const Register = () => {
  return (
    <>
      {/* <MainHeader /> */}
      <RegistrationForm />
      {/* <Footer /> */}
    </>
  );
};

export default Register;
