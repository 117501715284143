const BP1 = '@media (max-width: 899px)';
const MIN_WIDTH = '@media (min-width: 899px)';

export const sx = {
  root: {
    textAlign: 'left',
    mx: '5%',
    pt: '10%',
    pb: '10%',
  },
  heading: {
    fontSize: '42px',
    fontStyle: 'normal',
    fontFamily: 'SchnyderM-Light-App',
    fontWeight: 500,
    lineHeight: '50px',
    letterSpacing: '4px',
    pt: '2%',
    pb: '5%',
  },
  artContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '30px 30px',
    [BP1]: {
      flexWrap: 'nowrap',
      flexDirection: 'column',
      overflowX: 'auto',
    },
  },
  imageContainer: {
    width: '309px',
    height: '309px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#0E0E0E',
    borderRadius: '32px',
    transition: '0.2s',
    '& > img': {
      borderRadius: '32px',
    },
    [MIN_WIDTH]: {
      '&:hover': {
        transform: 'scale(1.2)',
        transition: '0.2s',
      },
    },
    [BP1]: {
      width: '100%',
      height: '100%',
      '& + &': {
        marginLeft: 0,
        mt: '30px',
      },
    },
  },
};
