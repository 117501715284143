
export const data =  [
  {
    background: 'linear-gradient(179.99deg, #93B4BB 0.01%, #457582 99.99%)',
    mainText: 'The In-Between',
    subText: 'Your eyes are open, but all you see is white.',
    extraSubText: 'The air is cold. It cuts through to the bone.',
  },
  {
    background: 'linear-gradient(179.99deg, #90A0B7 0.01%, #606C8B 99.99%)',
    mainText: 'A Game of Trust',
    subText: 'The world fades into view slowly, as if unfolding away from you: the cliff first, then the spires and the frozen, white plain.',
    extraSubText: 'The crumbling tower beyond.',
  },
  {
    background: 'linear-gradient(179.99deg, #A28EAE 0.01%, #615071 99.99%)',
    mainText: 'The Power and the Flaw ',
    subText: 'The world comes back with a slow unfurling, like a carpet rolling away. ',
  },
  {
    background: 'linear-gradient(179.99deg, #68617E 0.01%, #3C374A 99.99%)',
    mainText: 'Then Came the Flood ',
    subText: 'The world fades in again, yet something feels different this time. ',
  },
  {
    background: 'linear-gradient(179.99deg, #7D6D83 0.01%, #A37D88 99.99%)',
    mainText: 'What Lies Beneath ',
    subText: 'The city around you slowly fades into view again.',
    extraSubText: 'By now, most of the water has drained from the streets, but the fractured spires still drip with tiny waterfalls.',
  },
]

export const heading = 'An 11-quest adventure where reality and imagination collide';
export const footerText = 'As you travel through a dreamlike world that responds to your emotions and makes real what you think, a mysterious guide known only as "the voice" helps you find clarity, courage and self-insight.'