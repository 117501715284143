import React from 'react';
import { Box, createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import routes from './navigation/routes';
import Views from './views/Views';

const COLORS = {
	blue1: '#191622',
	blue2: '#00A5F7',
	blue3: '#7FC4FD',
	blue4: '#BCE0FD',
	grey: '#F1F9FF',
	light: '#FFF',
	betwixt_text_blue: '#2C4C6A',
	betwixt_dark_blue: '#191622',
};

const sx = {
	root: {
		position: 'relative',
	},
};

const theme = createTheme({
	typography: {
		fontFamily: 'jotia',
		fontSize: '16px',
		heading1: {
			fontFamily: 'jotia',
			fontSize: '48px',
			color: COLORS.betwixt_text_blue,
			fontWeight: 700,
			lineHeight: '68px',
		},
		heading2: {
			fontFamily: 'jotia',
			fontSize: '36px',
			color: COLORS.betwixt_text_blue,
			fontWeight: 700,
			lineHeight: '48px',
		},
	},
	palette: {
		primary: {
			main: COLORS.betwixt_text_blue,
			dark: COLORS.betwixt_dark_blue,
		},
		background: {
			socialIcon: COLORS.blue2,
			grey: COLORS.grey,
			infoBox: COLORS.blue3,
			image: COLORS.blue4,
			light: COLORS.light,
		},
		light: {
			main: '#FFF',
		},
		text: {
			primary: COLORS.betwixt_text_blue,
		},
		error: {
			main: '#EE1B11',
		},
	},
	components: {
		MuiTypography: {
			styleOverrides: {
				body1: {
					color: COLORS.betwixt_text_blue,
					fontFamily: 'jotia',
					fontSize: '18px',
				},
			},
			defaultProps: {
				variantMapping: {
					body1: 'div',
					heading1: 'div',
					heading2: 'div',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				contained: {
					color: '#FFF',
					height: '48px',
					fontSize: '16px',
					fontWeight: 600,
				},
			},
		},
	},
});

const App = () => (
	<ThemeProvider theme={theme}>
		<Box sx={sx.root}>
			<Views />
		</Box>
	</ThemeProvider>
);

export default App;
