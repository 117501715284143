import React from 'react';
import { Box, Typography } from '@mui/material';
import { sx } from './TermsCss';
import { text } from './data';
import ReactHtmlParser from 'react-html-parser';


const Terms = () => {
  return (
    <>
      <Box sx={sx.headingContainer}>
        <Typography
          sx={sx.heading}
        >
          Terms and Conditions
      </Typography>
        <Typography
          sx={sx.subHeading}
        >
          last updated on May 2022
      </Typography>
        <Typography
          sx={sx.policy}
        >
          {ReactHtmlParser(text)}
        </Typography>
      </Box>
    </>
  );
}

export default Terms;