import React, { forwardRef } from 'react';
import { Box, Typography } from '@mui/material';
import { sx } from './TeamCss';
import BenefitCard from './components/TeamCard';
import { data } from './Data/data';

const Team = forwardRef((props, ref) => (
	<Box sx={sx.root}>
		<Typography id="team" sx={sx.heading} color='background.light' variant='heading2' align='center' ref={ref}>The Betwixt Team</Typography>
		<Box sx={sx.utilContainer}>
			<Box sx={sx.cardContainer}>
				{
					data.map((card) => (
						<BenefitCard
							key={card.name}
							card={card}
							image={card.image}
							name={card.name}
							surname={card.surname}
							post={card.post}
							text={card.text}
						/>
					))
				}
			</Box>
		</Box>
	</Box>
));

export default Team;
