const BP1 = '@media (max-width: 899px)';

export const sx = {
  root: {
    background: '#0E0E0E',
    pt: '8%',
    pb: '5%',
    px: '10%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5%',
    [BP1]: {
      px: '0',
    },
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    pb: '5%',
    [BP1]: {
      flexDirection: 'column',
    },
  },
  heading: {
    fontSize: '42px',
    fontStyle: 'normal',
    fontFamily: 'SchnyderM-Light-App',
    fontWeight: 500,
    lineHeight: '5px',
    letterSpacing: '4px',
    textAlign: 'center',
    pb: '5%',
    [BP1]: {
      fontSize: '30px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '50px',
      letterSpacing: '4px',
    },
  },
};
