import React from 'react';
import { Box, Typography } from '@mui/material';
import SocialButton from './SocialButton';
import {sx, HeaderButton} from './FooterCss';


const Footer = ({teamRef, aboutRef}) => {
	const handleTeam = () => {
    if(teamRef?.current){
      teamRef.current.scrollIntoView();
    }
  }

	const handleAbout = () => {
		if (aboutRef?.current) {
			aboutRef.current.scrollIntoView();
		}
	};

	return(
		<Box sx={sx.root}>
			<Typography
				sx={{ ...sx.text, mt: '16px', }}
				>
				All rights reserved. 	&copy; Copyright 2022 Mind Monsters Games Ltd
			</Typography>
		</Box>
	);
};

export default Footer;
