import React from 'react';
import Banner from './components/Banner/Banner';
import Team from './components/Team/Team';
import Wallet from './components/Wallet/Wallet';
import Features from './components/Features/Features';
import Art from './components/Art/Art';
import Fantasy from './components/Fantasy/Fantasy';
import Collab from './components/Collab/Collab';
import ArtSample from './components/ArtSamples/ArtSample';

const Home = ({
	teamRef,
	aboutRef,
	utilityRef,
	discordButtonClick,
	mintButtonClick
}) => {

	return (
		<>
			<Banner mintButtonClick={mintButtonClick} />
			<Features ref={aboutRef} />
			<Art />
			<Fantasy mintButtonClick={mintButtonClick} />
			<Wallet ref={utilityRef} discordButtonClick={discordButtonClick} />
			<Team ref={teamRef} />
			<Collab />
			<ArtSample />
		</>
	);
};

export default Home;
