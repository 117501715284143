//TODO: REFACTOR. A lot of duplicated code with Registration.jsx

import React from 'react';
import { environment } from '../../../../environment';
import { isMobile } from 'react-device-detect';
import { Typography, Box } from '@mui/material';
import { sx, CssTextField, SubmitButton } from './NFTConnectCss';

import { ethers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { firebaseConfig } from '../../../../credentials/firebase';
import { smartContractAddress, network } from '../../../../credentials/blockchain';


firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();
var firestoreUserId;

const message = "Welcome to Betwixt. Please sign to verify your ownership of a Betwixt NFT. This request will not trigger any blockchain transaction. It will not cost any gas fees.";
const contractAddress = smartContractAddress; //Mainnet or testnet NFT smart contract address depending on environment
var provider;
var signer;
var signature;
var address;
var signerAddr;
var verifiedAddress;
var signer2;
var abi;
var tokenContract;
var NFTBalance = 0;
var ownedNFTs = new Array();
var unlinkedNFTs = new Array();
var errorCase = false;
var errorMessage = "";
var waitingState = false;

class NFTConnectionForm extends React.Component {

  constructor(props) {
    super(props);
    this.passwordProps = {};

    this.successfulLogin = false;

    this.state = { email: '', password: '' };

    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePass = this.handleChangePass.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.NFTOwnershipVerified = false;
    this.NFTOwnershipVerificationFailed = false;
    this.handleNFTVerification = this.handleNFTVerification.bind(this);

  }


  async walletButtonClick() {
    try {
      if (!isMobile && !window.ethereum) {
        let message2 = "Crypto wallet not found.Please install it.";
        alert(message2);
        errorCase = true;
        errorMessage = message2;
        // throw new Error(message2);
      }
      else {
        // console.log("Starting walletButtonClick()");

        const providerOptions = {
          /* See Provider Options Section */
          walletconnect: {
            package: WalletConnectProvider, //required
            options: {
              rpc: {
                1: "https://cloudflare-eth.com/",
                4: "https://rpc.ankr.com/eth_rinkeby",
                5: "https://rpc.ankr.com/eth_goerli",
                // 5: "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
                // 3: "https://ropsten.mycustomnode.com",
                137: "https://polygon-rpc.com/",
                // ...
              },
            }
          }
        }

        const web3Modal = new Web3Modal({
          network: network(), // optional
          cacheProvider: false, // optional
          providerOptions // required
        });
        // console.log("network: " + network());

        const instance = await web3Modal.connect();

        provider = new ethers.providers.Web3Provider(instance);
        signer = provider.getSigner();

        // Subscribe to accounts change
        provider.on("accountsChanged", (accounts) => {
          console.log("accounts: " + accounts);
        });
        // Subscribe to chainId change
        provider.on("chainChanged", (chainId) => {
          console.log("chain ID: " + chainId);
        });
        // Subscribe to provider connection
        provider.on("connect", (info) => {
          console.log("info: " + info);
        });
        // Subscribe to provider disconnection
        provider.on("disconnect", (error) => {
          console.log("on disconnect error: " + error);
        });

        // console.log("Connecting wallet...");
        await provider.send("eth_requestAccounts", []);

        // console.log("End walletButtonClick()");
      }

    } catch (ex) {
      console.log("Error: " + ex);

      //Skip the "Method not found" error as it seems to be a fake error from WalletConnect? Need to look deeper into this
      if (ex.message != "Method not found") {
        errorCase = true;
        errorMessage = errorMessage + ". " + ex.message;
      }
    }
  }

  async sign() {
    try {

      // console.log("Starting sign()");
      signature = await signer.signMessage(message);
      // console.log("Signature: " + signature);
      // console.log("type of signature: " + typeof (mySignature));
      address = await signer.getAddress();
      // console.log("Address of signer: " + address);

      // console.log("end Sign().")
    }
    catch (ex) {
      console.log("Error: " + ex.message);
      errorCase = true;
      errorMessage = errorMessage + ". " + ex.message;
    }
  }

  async verifySignature() {
    try {
      // console.log("Starting verifySignature()");
      signerAddr = await ethers.utils.verifyMessage(message, signature);
      // console.log("signerAddr: " + signerAddr);
      if (signerAddr !== address) {
        console.log("Addresses do not match! Signature verification failed.");
      }
      else {
        verifiedAddress = signerAddr;
        waitingState = true;
        // console.log("Signature verification successful!");
        this.forceUpdate();
      }

      // console.log("End verifySignature()");
    }
    catch (ex) {
      console.log(ex);
      errorCase = true;
      errorMessage = errorMessage + ". " + ex.message;
    }
  }

  initiateTokenContract() {
    signer2 = new ethers.VoidSigner(verifiedAddress, provider);
    abi = [
      "function balanceOf(address owner) external view returns (uint256 balance)",
      "function tokenOfOwnerByIndex(address owner, uint256 index) external view returns (uint256)",
    ];
    tokenContract = new ethers.Contract(contractAddress, abi, signer2);
  }

  async getNFTBalance() {
    NFTBalance = await tokenContract.balanceOf(signer2.getAddress());
    // console.log("Balance: " + NFTBalance);
  }

  async getOwnedNFTs(tokenContract, signer2, balance) {
    // console.log("Start getOwnedNFTs");
    try {
      if (balance > 0) {
        let index = 0;
        var NFTID = 0;
        let ownedNFTsIndex = 0;
        while (index < balance) {
          NFTID = await tokenContract.tokenOfOwnerByIndex(signer2.getAddress(), index);
          // console.log("pushing " + NFTID + " to the array of owned NFTs");
          ownedNFTs[ownedNFTsIndex] = NFTID;
          ownedNFTsIndex = ownedNFTsIndex + 1;
          index = index + 1;
        }
      }
    }
    catch (ex) {
      console.log(ex);
    }
    // console.log("End getOwnedNFTs");
  }

  async getUnlinkedNFTs() {
    // console.log("Start getunlinkedNFTs");
    try {
      if (NFTBalance > 0) {
        let unlinkedNFTsIndex = 0;
        let isLinked;
        for (let tokenID of ownedNFTs) {
          const linked = await this.checkIfNFTAlreadyLinked(tokenID);
          isLinked = linked;
          if (!isLinked) {
            // console.log("pushing " + tokenID + " to the array of unlinked NFTs");
            unlinkedNFTs[unlinkedNFTsIndex] = tokenID;
            unlinkedNFTsIndex = unlinkedNFTsIndex + 1;
          }
        };
      }
    }
    catch (ex) {
      console.log(ex);
    }
    // console.log("End getunlinkedNFTs");
  }

  async checkIfNFTAlreadyLinked(NFTID) {
    // console.log("Start checkIfNFTAlreadyLinked");
    const usersRef = firestore.collection('users');

    //limit 10,000 for now to prevent cases of firebase read quota over-usage. Increase as size of database increases.
    const snapshot = await usersRef.where('NFTCollection', '==', 'Elder').where('NFTID', '==', NFTID.toNumber()).limit(10000).get();
    if (snapshot.empty) {
      // console.log(NFTID + " not linked");
      // console.log("End checkIfNFTAlreadyLinked");
      return false;
    }
    else {
      snapshot.forEach(doc => {
        // console.log(NFTID + " linked to ");
        // console.log(doc.id, '=>', doc.data());
      });
      // console.log("End checkIfNFTAlreadyLinked");
      return true;
    }
  }

  async verifyOwnership() {
    try {
      // console.log("Start verifyOwnership()");

      if (NFTBalance > 0) {
        if (unlinkedNFTs.length > 0) {
          // console.log("NFT ownership verified");
          this.NFTOwnershipVerified = true;
        }
        else {
          this.NFTOwnershipVerificationFailed = true;
        }
      }
      // this.listOwnedNFTs(tokenContract, signer2, balance);
      else {
        this.NFTOwnershipVerificationFailed = true;
      }
      waitingState = false;
      // console.log("end verifyOwnership");
    }
    catch (ex) {
      console.log(ex);
      errorCase = true;
      errorMessage = errorMessage + ". " + ex.message;
    }
  }





  async listOwnedNFTs(tokenContract, signer2, balance) {
    try {
      let index = 0;
      var NFTID = 0;
      while (index < balance) {
        NFTID = await tokenContract.tokenOfOwnerByIndex(signer2.getAddress(), index);
        console.log("Listing owned NFTs. ID: " + NFTID);
        alert("You own NFT ID: " + NFTID);
        index = index + 1;
      }
    }
    catch (ex) {
      console.log(ex);
    }
  }

  async linkNFTWithBetwixtAccount(userID, tokenID) {
    // console.log("Start linkNFTWithBetwixtAccount");
    try {
      const usersRef = firestore.collection('users').doc(userID);

      usersRef.get()
        .then((docSnapshot) => {
          if (docSnapshot.exists) {
            // console.log("user document exists");
            usersRef.onSnapshot((doc) => {
              usersRef.update({
                email: this.state.email,
                usrid: userID,
                ethereumWallet: verifiedAddress,
                NFTCollection: 'Elder',
                NFTID: tokenID
              });
            });
          } else {
            // console.log("user document doesn't exist");
            usersRef.set({
              email: this.state.email,
              usrid: userID,
              ethereumWallet: verifiedAddress,
              NFTCollection: 'Elder',
              NFTID: tokenID
            }, { merge: true }) // create the document
          }
        });

      // console.log("Linked account: " + firestoreUserId + " with NFT ID: " + tokenID);
    }
    catch (ex) {
      console.log(ex);
    }
    // console.log("End linkNFTWithBetwixtAccount");
  }



  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  handleChangePass(event) {
    this.setState({ password: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!this.state.email) {
      alert("Email cannot be empty")
    }
    else {
      this.loginInFirebase(this.state.email, this.state.password).then(res => {
        if (this.NFTOwnershipVerified && this.successfulLogin && firestoreUserId) {
          this.linkNFTWithBetwixtAccount(firestoreUserId, unlinkedNFTs[0].toNumber()); //link the first element of the array of unlinked NFTs
        }
      });
    }
  }

  handleNFTVerification(event) {
    event.preventDefault();
    this.NFTVerificationChain();
  }

  render() {

    if (waitingState == true) {
      return (
        <>
          <Box
            sx={sx.boxSuccessCss}
          >
            <Typography sx={sx.heading} >
              Please wait...
            </Typography>
            <Typography sx={sx.text} >
              We are running a bunch of checks...
              <br></br><br></br>
              This may take a minute.
            </Typography>
          </Box>
        </>
      );
    }
    else if (errorCase == true) {
      return (
        <>
          <Box
            sx={sx.boxSuccessCss}
          >
            <Typography sx={sx.heading} >
              Error
            </Typography>
            <br></br>
            <Typography sx={sx.text} >
              There has been an error.
              <br></br><br></br>
              {errorMessage}
            </Typography>
          </Box>
        </>
      );
    }

    else if (this.NFTOwnershipVerificationFailed == true) {
      return (
        <>
          <Box
            sx={sx.boxSuccessCss}
          >
            <Typography sx={sx.heading} >
              Verification Failed
            </Typography>
            <br></br>
            <Typography sx={sx.text} >
              You’ve either linked all your NFTs already or your wallet doesn’t hold a Betwixt Elder NFT.
              <br></br><br></br>
              If you need further help, please contact us in our Discord server: https://discord.com/invite/YM88YkMJ8J
              <br></br>
              Alternatively, drop us an email at hazel@betwixt.life
            </Typography>
          </Box>
        </>
      );
    }
    else if (this.NFTOwnershipVerified && this.successfulLogin) {
      return (
        <>
          <Box
            sx={sx.boxSuccessCss}
          >
            <Typography sx={sx.heading} >
              Success
            </Typography>
            <br></br>
            <Typography sx={sx.text} >
              Your <b>NFT # {unlinkedNFTs[0].toNumber()}</b> has been connected to your Betwixt account.
              <br></br><br></br>
              You can now open the Betwixt app to continue your journey through the game.
            </Typography>
          </Box>
        </>
      );
    }
    else if (this.NFTOwnershipVerified && !this.successfulLogin) {

      return (
        <>
          <Box
            component="form"
            onSubmit={this.handleSubmit}
            sx={sx.boxCss}
          >
            <Typography sx={sx.heading} >
              Connect your NFT
            </Typography>
            <Typography sx={sx.text} >
              Please enter the account details you use to log into the Betwixt app. If you don’t remember them, please send an email to hazel@mindmonsters.co.uk
            </Typography>
            <CssTextField fullWidth label="Email" type="email" value={this.state.email} onChange={this.handleChangeEmail} />
            <CssTextField fullWidth label="Password" type="password" value={this.state.password} onChange={this.handleChangePass} />

            <Box sx={sx.btnContainer}>
              <SubmitButton sx={sx.submitButtonCss} type="submit">Submit</SubmitButton>
            </Box>
          </Box>
        </>
      );
    }

    else if (!this.NFTOwnershipVerified) {
      return (
        <Box
          component="form"
          onSubmit={this.handleNFTVerification}
          sx={sx.boxCss}
        >
          <Typography sx={sx.heading} >
            Connect your NFT
          </Typography>
          <Typography sx={sx.text} >
            To connect your NFT to your Betwixt account and generate the art, we need to re-verify your ownership.
          </Typography>
          <Box sx={sx.btnContainer}>
            <SubmitButton sx={sx.verifyButtonCss} type="submit">Connect NFT</SubmitButton>
          </Box>
        </Box>
      );
    }
  }



  async NFTVerificationChain() {
    //TODO
    try {
      this.walletButtonClick()
        .then(() => this.sign())
        .then(() => this.verifySignature())
        .then(() => this.initiateTokenContract())
        .then(() => this.getNFTBalance())
        .then(() => this.getOwnedNFTs(tokenContract, signer2, NFTBalance))
        .then(() => this.getUnlinkedNFTs())
        .then(() => this.verifyOwnership())
        .then(() => {
          // console.log("finished verification chain");
          // console.log("forcing update");
          this.forceUpdate();
        });

    }
    catch (ex) {
      console.log(ex);
      errorCase = true;
      errorMessage = errorMessage + ". " + ex.message;
    }


  }

  loginInFirebase(username, password) {
    return auth.signInWithEmailAndPassword(username, password).then(async (res) => {
      this.successfulLogin = true;
      this.forceUpdate();
      firestoreUserId = res.user.uid;
    }
    ).catch(error => {
      console.log("Error creating user: ");
      console.log(error);
      let authfail = error['message'].includes("The password is invalid") || error['message'].includes("There is no user record corresponding to this identifier");
      let badformat = error['message'].includes("The email address is badly formatted");
      let weakpassword = error['message'].includes("Password should be at least 6 characters");
      let toomanyfailedattempts = error['message'].includes("Access to this account has been temporarily disabled due to many failed login attempts")

      if (authfail) {
        alert("Sorry, login failed. The email and password combination seems to be incorrect.");
      }

      if (badformat) {
        alert("Invalid Email Format");
      }
      if (weakpassword) {
        alert("Password should be at least 6 characters")
      }
      if (toomanyfailedattempts) {
        alert("Access to this account has been temporarily disabled due to many failed login attempts. Please contact us on Discord: https://discord.com/invite/YM88YkMJ8J");
      }
      return false;
    });
  }
};

/* eslint-disable react/forbid-prop-types */

export default NFTConnectionForm;
