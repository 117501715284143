import React from 'react';
import { Box, Button, Typography } from '@mui/material';

import SocialButton from './SocialButton';
import { sx, HeaderButton, JoinButton } from './MainHeaderCss';

const MainHeader = ({ teamRef, aboutRef, utilityRef, discordButtonClick, mintButtonClick }) => {

  const handleUtility = () => {
    if (utilityRef?.current) {
      utilityRef.current.scrollIntoView();
    } else {
      window.location.href = '/#utility';
    }
  };

  const handleAbout = () => {
    if (aboutRef?.current) {
      aboutRef.current.scrollIntoView();
    } else {
      window.location.href = '/#about';
    }
  };

  const handleTeam = () => {
    if (teamRef?.current) {
      teamRef.current.scrollIntoView();
    } else {
      window.location.href = '/#team';
    }
  }

  return (
    <Box sx={sx.root}>
      <Box sx={sx.menuContainer}>
        <HeaderButton color='light' onClick={handleAbout}>
          <Typography sx={sx.menuButton}>About</Typography>
        </HeaderButton>
        <HeaderButton color='light' onClick={handleTeam}>
          <Typography sx={sx.menuButton}>Team</Typography>
        </HeaderButton>
        <HeaderButton color='light' onClick={handleUtility}>
          <Typography sx={sx.menuButton}>Utility</Typography>
        </HeaderButton>
      </Box>
      <Box sx={sx.logoContainer}>
        <Box sx={sx.circle1} />
        <Box sx={sx.circle2} />
        <Box sx={sx.circle3} />
        <Box sx={sx.circle4} />
        <Box sx={sx.circle5} />

        <a href="/" style={{ textDecoration: "none" }}>
          <Typography sx={sx.text}>Betwixt</Typography>
        </a>
        <Typography
          color='primary.light'
          fontFamily='Open Sans'
          sx={sx.subText}
        >
          THE STORY OF YOU
        </Typography>
      </Box>
      <Box sx={{ ...sx.rightMenuContainer, ...sx.socialContainer, }}>
        <SocialButton variant='discord' />
        <SocialButton variant='twitter' />
        <Box sx={sx.btnContainer}>
          <JoinButton
            sx={sx.buttonCss}
            onClick={mintButtonClick}
          >
            Mint Now
					</JoinButton>
        </Box>
      </Box>
    </Box>
  );
};

export default MainHeader;