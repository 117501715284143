const BP1 = '@media (max-width: 899px)';

export const sx = {
	root: {
		px: '20px',
		pt: '9%',
		pb: '8%',
		position: 'relative',
	},
	rectangle: {
		width: '8px',
		height: '8px',
		position: 'absolute',
		left: '50%',
		right: '16.92%',
		top: '10%',
		bottom: '38.79%',
		background: 'white',
		boxShadow: '0px 2.93449px 8.80347px rgba(255, 255, 255, 0.35)',
		transform: 'rotate(45deg)',
		[BP1]: {
			top: '0%',
		},
	},
	headingContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	heading: {
		fontSize: '42px',
		fontStyle: 'normal',
		fontFamily: 'SchnyderM-Light-App',
		fontWeight: 500,
		lineHeight: '50px',
		letterSpacing: '4px',
		textAlign: 'center',
		[BP1]: {
			fontSize: '32px',
			letterSpacing: '2px',
			lineHeight: '38px',
		},
	},
	footerContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		order: 2,
		mt: '10px',
		[BP1]: {
			width: '100%',
		},
	},
	footerHeading: {
		width: '48%',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: '800',
		color: 'rgba(255, 255, 255, 0.6)',
		fontFamily: 'Open Sans',
		textShadowColor: 'rgba(255, 255, 255, 0.25)',
		textShadowOffset: { width: 0, height: 0 },
		textShadowRadius: 8,
		mt: '30px',
		[BP1]: {
			width: '100%',
			fontSize: '15px',
			mt: '15px',
		},
	},
	cardContainer: {
		mt: '80px',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		justifyContent: 'center',
		overflowX: 'auto',
		padding: '20px',
		[BP1]: {
			justifyContent: 'normal',
		},
	},
	btnContainer: {
		padding: '60px',
	},
};
