import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import Rectangle from '../../../../assets/images/logo/Rectangles.png';

export const JoinButton = styled(Button)({
	boxShadow: 'none',
	textTransform: 'none',

	'&:active': {
		boxShadow: 'none',
	},
});

const BP1 = '@media (max-width: 899px)';

export const sx = {
	root: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexWrap: 'wrap',
		pt: '8%',
		pb: '8%',
		pl: '7%',
		pr: '7%',
		[BP1]: {
			flexDirection: 'column-reverse',
			pl: 0,
			pr: 0,
			pt: '10%',
			gap: '1%',
		},
	},
	rectangle: {
		width: '8px',
		height: '8px',
		position: 'absolute',
		left: '50%',
		right: '16.92%',
		top: '8%',
		bottom: '38.79%',
		background: 'white',
		boxShadow: '0px 2.93449px 8.80347px rgba(255, 255, 255, 0.35)',
		transform: 'rotate(45deg)',
		[BP1]: {
			top: '0%',
		},
	},
	heading: {
		fontSize: '42px',
		fontStyle: 'normal',
		fontFamily: 'SchnyderM-Light-App',
		fontWeight: 'normal',
		lineHeight: '50px',
		letterSpacing: '4px',
		textAlign: 'center',
		color: '#FFFFFF',
		textShadowColor: 'rgba(255, 255, 255, 0.55)',
		textShadowOffset: { width: 0, height: 0 },
		textShadowRadius: 57.321,
		padding: '5% 0',
		[BP1]: {
			fontSize: '32px',
			lineHeight: '38px',
		},
	},
	subHeading: {
		fontSize: '16px',
		lineHeight: '24px',
		fontWeight: '800',
		textAlign: 'center',
		fontFamily: 'Open Sans',
		color: 'rgba(255, 255, 255, 0.6)',
		textShadowColor: 'rgba(255, 255, 255, 0.25)',
		textShadowOffset: { width: 0, height: 0 },
		textShadowRadius: 8,
		// padding: '2% 4%',
		[BP1]: {
			fontSize: '15px',
			mt: '15px',
		},
	},
	leftContainer: {
		width: '50%',
		maxWidth: '516px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		pt: '5%',
		[BP1]: {
			width: '100%',
			mt: '84px',
		},
	},
	rightContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '42%',
		minWidth: '250px',
		position: 'relative',
		backgroundColor: '#190C29',
		height: '676px',
		borderRadius: '32px',
		[BP1]: {
			width: '100%',
			height: '416px',
		},
	},
	overlayImage1: {
		position: 'absolute',
		top: '10%',
		left: '32%',
		[BP1]: {
			left: '28%',
			width: '64%',
		},
	},
	overlay1: {
		width: '100%',
		[BP1]: {
			width: '1%',
		},
	},
	overlay2: {
		width: '100%',
		[BP1]: {
			display: 'none',
		},
	},
	overlayImage2: {
		position: 'absolute',
		top: '8%',
		left: '28%',
		[BP1]: {
			left: '24%',
			width: '44%',
		},
	},
	btnContainer: {
		width: '228px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '8px 30px',
		fontFamily: 'Open Sans',
		background: 'linear-gradient(102.89deg, #904DDC -14.44%, #7719A4 98.48%)',
		margin: '60px 0',
		order: '1',
		borderRadius: '10px',
		border: '1px solid rgba(45, 46, 50, 0.15)',
		backdropFilter: 'blur(12px)',
		'&:hover': {
			background: 'linear-gradient(102.89deg, #6632A2 -14.44%, #5A107D 98.48%)',
			cursor: 'pointer',
		},
		[BP1]: {
			order: 0,
			width: '80%',
			margin: '60px 0',
			padding: '4px 15px',
		},
	},
	buttonCss: {
		fontSize: '16px',
		lineHeight: '24px',
		fontWeight: '800',
		textAlign: 'left',
		fontFamily: 'Open Sans',
		color: '#ffffff',
		textShadowColor: 'rgba(255, 255, 255, 0.25)',
		textShadowOffset: { width: 0, height: 0 },
		textShadowRadius: 8,
	},
	backgroundImage: {
		width: '100%',
	},
};
