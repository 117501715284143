const BP1 = "@media (max-width: 899px)";

export const sx = {
  headingContainer: {
    marginTop: "100px",
    minHeight: "700px",
    textAlign: "center",
    pl: "7%",
    pr: "7%",
    [BP1]: {
      marginTop: "50px",
    },
  },
  heading: {
    fontSize: "42px",
    fontStyle: "normal",
    fontFamily: "SchnyderM-Light-App",
    fontWeight: "normal",
    lineHeight: "50px",
    letterSpacing: "4px",
    textAlign: "center",
    color: "#FFFFFF",
    textShadowColor: "rgba(255, 255, 255, 0.55)",
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 57.321,
    [BP1]: {
      fontSize: "32px",
      lineHeight: "38px",
      marginBottom: '20px'
    },
  },
  subHeading: {
    margin: "20px 0px",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "800",
    textAlign: "center",
    fontFamily: "Open Sans",
    color: "rgba(255, 255, 255, 0.6)",
    textShadowColor: "rgba(255, 255, 255, 0.25)",
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 8,
    [BP1]: {
      fontSize: "15px",
      mt: "15px",
      marginBottom: '50px'
    },
  },
  policy: {
    margin: "100px 0px",
    padding: "0px 100px",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "800",
    textAlign: "left",
    fontFamily: "Open Sans",
    color: "rgba(255, 255, 255, 0.6)",
    textShadowColor: "rgba(255, 255, 255, 0.25)",
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 8,
    [BP1]: {
      fontSize: "15px",
      mt: "15px",
      padding: "0",
    },
    '& b': {
      fontSize: '16px',
      color: 'white'
    }
  },
};
