export const text = `
<br>
This Privacy Policy explains how Mind Monsters Games Ltd ("Betwixt","we", "us", "our",) collects, uses, and discloses information about you when you access or use our website, mobile application, and other online products and services (collectively, the "Services"), and when you otherwise interact with us. 
<br>
We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide you with additional notice (such as sending you a notification). 
<br>
<br>
<b>Collection of Information</b>
<br>

We collect the following kinds of data when you use the Application and our Services:
<li>your contact data such as your email address;
<li>your usage and log data such as what screens or features you access, the type of web browser you use, app version, access times and dates, pages viewed, your IP address.

This data is anonymised and then processed by Google Analytics and doesn’t personally identify you.

<br>
<li>The native actions you take within the App, such as picking from a number of available options and story paths. 

Your choices are processed by a rule-based engine in order to direct you to the relevant branch of the story. At no point during your interaction with the App does another natural person have access to or get to monitor or respond to your messages.

<br>
<li>The content you produce through the App. 

The only reason we collect your responses is so we can store them for you and ensure you don’t lose access to your insights if you switch phones. 

We don’t want to know your thoughts. We have no incentive to, either. Our model isn’t based on selling data or advertising or marketing additional services to you.  All we care about is creating a great product that makes people’s lives a little better. 


<li>The information you provide to us when you fill out a form or a survey, participate in a contest or promotion, make a purchase, communicate with us via social media sites, request customer support, or otherwise communicate with us.
<br>
<br>
<b>Use of Information</b>
<br>
We use the information we collect to:
<li>Create and manage your account in order for you to access and use the Services;
<li>Personalize and enhance your user experience by making the story reflect your past choices;
<li>Monitor and analyze trends, usage, and activities in connection with our Services;
<li>Respond to law enforcement requests and comply with the law, in particular, for the purpose of detecting, investigating, and prosecuting illegal acts.
<li>Detect, investigate and prevent fraudulent transactions and other illegal activities and protect our rights and property and those of others, including to enforce our agreements and policies. 
<li>We may also de-identify and/or aggregate and/or anonymize your data for purposes of product development and improvement. De-identified data, in individual or aggregated form, may also be used for research purposes both internally or with research partners for the advancement of clinical and scientific knowledge.
<li>We use third party service providers who provide technical and support services to help us provide and improve the product and Services. In providing the Services, these third party service providers may have limited access to databases of user information or registered member information solely for the purpose of helping us to improve the product and they will be subject to contractual restrictions prohibiting them from using the personal data of our members for any other purpose.

<br>
<br>
<b>Cookies and similar technologies</b>
When you use the Services, We may use cookies and other IT functions to collect your Data. Cookies are small text files that are automatically created by your browser and stored on your device when you connect to the Website or the Application and use the Services.

<br>You have the choice to refuse to keep all or certain of the cookies in your browser or to disable all or certain cookies, after accepting their deposit on your browser, by configuring your settings. With regard to the use of advertising-related or personalization-related technologies, you can determine whether you consent or not to the use of cookies for marketing purposes.

<br>
<br>
<b>Website analytics</b>

<br>
We may also partner with selected third-party vendors, such as Google Analytics, to allow tracking technologies and remarketing services on the App through the use of first party cookies and third-party cookies, to, among other things, analyze and track users’ use of the App and better understand online activity. By accessing the App or our Site, you consent to the collection and use of your information by these third-party vendors. You are encouraged to review their privacy policy and contact them directly for responses to your questions. We do not transfer personal information to these third-party vendors.

<br>
<br>
<b>Security of your data</b>
<br>

We seek to safeguard the security of your Data and have implemented security measures consistent with accepted practices in the industry to protect your data and limit access to it. 
‍
<br>
In particular, we have implemented appropriate technical and organizational measures to minimize risks associated with data loss, misuse, unauthorized access, and unauthorized disclosure using encryption technology, such as Secure Sockets Layer (SSL) during data transport and at rest as well as physical access restrictions for our data centers and authorization controls for data access. However, despite our efforts to protect your Data, there is always some risk that an unauthorized third party may find a way around our security systems or that transmissions of your Data over the Internet will be intercepted. Unfortunately, we cannot guarantee the absolute security of your data, nor can we guarantee that the Data that you provide will not be intercepted while being transmitted to Us over the Internet. Therefore, we urge you to also take every precaution to protect your Data when you are on the Internet or using the Application.

<br>
<br>
<b>Storage of personal data</b>
<br>

We will keep your personal data for as long as it is necessary to fulfill the purposes for which it was collected as described above and in accordance with our legal and regulatory obligations.
<br>
If you would like further information about our data retention practices you can ask for this at any time (see “Contact Us” section below).

<br>
<br>
<b>How we store your data</b>
<br>

We process your information and store it on servers located in data centers in the EEA. Our server environment is highly secure and any information is encrypted with industry standard techniques.


<br>
Unfortunately, the transmission of information via the internet is not completely secure. Although We will do Our best to protect your personal data, We cannot guarantee the security of your data transmitted to Our Site(s); any transmission is at your own risk. Once We have received your information, We will use strict procedures and security features to try to prevent unauthorised access.
<br>
<br>
<b>Legal basis of personal data processing</b>
<br>
In accordance with GDPR, the lawful basis we use for collecting and processing your information are as follows:
<li>Where it is necessary for entering into or performing a contract with you;
<li>Where we have a legitimate interest to do so, provided your rights do not override those interests;
<li>Where you have consented to its uses;
<li>Where our colleagues believe it is in your vital interests to share your personal details;
<li>Where required to comply with our legal obligations.
<br>
<br>
<b>Your rights</b>
<br>
Subject to certain exemptions, and in some cases dependent upon the processing activity we are undertaking, you have certain rights in relation to your personal information.
<li>Right to information: You can request a copy of the personal information we have collected about you.
<li>Right to rectification: We want to ensure that your information is up to date and correct. You can request that your information be corrected or removed if you consider it incorrect.
<li>Right to be forgotten: You can request us to delete your personal information. We may not delete data that the law requires us to keep.
<li>Data portability: You can request that we transfer your personal data from our IT environment, either to another company or to you. This does not apply to information that the law requires us to keep.
<li>Withdrawal of consent: You can withdraw your consent to share your information or to receive marketing / emails at any time. Either by unsubscribing from the mailing list or by contacting us through email.
<li>Complaint: You can file a complaint with the Data Protection Authorities if you believe that we are treating your personal data in violation with GDPR.
<br>
You can exercise your rights by contacting us by email at hazel@mindmonsters.co.uk. Subject to legal and other permissible considerations, we will make every reasonable effort to honour your request promptly or inform you if we require further information in order to fulfil your request.
<br>
<br>
<b>Children’s information​</b>
<br>
The Services are not directed to children under 13 (or other age as required by local law), and we do not knowingly collect personal data from children. If you learn that your child has provided us with personal data without your consent, you may contact us as set forth below. If we learn that we have collected any personal data in violation of applicable law, we will promptly take steps to delete such personal data and terminate the child’s account.
<br>
<br>
<b>Contact</b>
<br>
Please send any questions, comments or requests regarding this privacy policy to our team at hazel@mindmonsters.co.uk. You can also write to us at the following address – Mind Monsters Games Ltd,12 Whitmore House, 140 Nuttall Street, London N1 5LJ.
<br>
If you feel that we have not addressed your questions or concerns adequately, or you believe that your data protection or privacy rights have been infringed, you can complain to any supervisory authority or other public body with responsibility for enforcing privacy laws. In the United Kingdom this is the Information Commissioner’s Office, you can see their contact details via the ICO website.
`;