import React from 'react';
import { Box, Typography } from '@mui/material';
import { sx } from './ArtSampleCss';
import art1 from '../../../../assets/images/art/updated_art1.jpg';
import art2 from '../../../../assets/images/art/updated_art2.jpg';
import art3 from '../../../../assets/images/art/updated_art3.jpg';
import art4 from '../../../../assets/images/art/updated_art4.jpg';

const ArtSample = () => (
	<Box sx={sx.root}>
		<Typography
			color='background.light'
			sx={{
				...sx.text,
				...sx.heading,
			}}
			fontFamily='Roboto'
			textshadow='0px 0px 57.321px rgba(255, 255, 255, 0.55)'
		>
			Art Samples
		</Typography>
		<Box sx={sx.artContainer}>
			{
				[art1, art2, art3, art4].map((image, index) => (
					<Box sx={sx.imageContainer} key={`art${index}`}>
						<img src={image} style={{ width: '100%' }} alt='art-image' />
					</Box>
				))
			}
		</Box>
	</Box>
);

export default ArtSample;
