import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const BP1 = '@media (max-width: 899px)';



export const sx = {
	root: {
		width: '100%',
		px: '20px',
		pt: '40px',
		pb: '48px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '100px',
		[BP1]: {
			gap: '10px',
			flexWrap: 'wrap',
		}
	},
	menuButton: {
		fontFamily: 'Open Sans',
		fontWeight: '800',
		fontStyle: 'normal',
		color: '#ffffff',
		textAlign: 'center',
		fontSize: '16px',
		textShadowColor: 'rgba(255, 255, 255, 0.25)',
		textShadowOffset: { width: 0, height: 0 },
		textShadowRadius: 8,
	},
	leftContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: '50px',
		[BP1]: {
			gap: '10px',
		}
	},
	text: {
		fontSize: '12px',
		lineHeight: '24px',
		fontWeight: '800',
		textAlign: 'right',
		fontFamily: 'Open Sans',
		color: '#C4C4C4',
		padding: '0 10%',
		[BP1]: {
			padding: '0',
		}
	},
	socialContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		gap: '50px',
		[BP1]: {
			px: '20px',
		}
	},
	button: {
		[BP1]: {
			fontSize: '16px',
		}
	},
};